<template>
  <div class="mod-userrecharge">
    <el-form
      :model="searchForm"
      @keyup.enter.native="getDataList()"
      ref="dataForm"
      inline
      class="bysearchForm"
    >
      <div class="searchLine">
        <el-form-item class="typeInput">
          <el-input
            v-model="searchForm.orderSn"
            placeholder="订单号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-input
            v-model="searchForm.userName"
            placeholder="用户名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="充值日期" class="typeInput">
          <el-date-picker
            v-model="value"
            class="el-popper DatepickerTime"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList()">查询</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table
      :data="dataList"
      stripe
      border
      @selection-change="selectionChangeHandle"
      style="width: 100%"
    >
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50"
      >
      </el-table-column>
      <el-table-column
        prop="orderSn"
        header-align="center"
        align="center"
        label="充值订单号"
      >
      </el-table-column>
      <el-table-column
        prop="userName"
        header-align="center"
        align="center"
        label="充值用户"
      >
      </el-table-column>

      <el-table-column
        prop="rechargeMoney"
        header-align="center"
        align="center"
        label="充值金额"
      >
        <template slot-scope="scope">
          <el-tag type="success" effect="dark" size="small"
            >￥{{ scope.row.rechargeMoney }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="realPay"
        header-align="center"
        align="center"
        label="实际支付"
      >
        <template slot-scope="scope">
          <el-tag effect="dark" size="small">￥{{ scope.row.realPay }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="rechargeTime"
        header-align="center"
        align="center"
        label="充值时间 "
      >
      </el-table-column>
      <el-table-column
        prop="fromType"
        header-align="center"
        align="center"
        label="充值来源"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.fromType == 1" type="success"
            >微信小程序</el-tag
          >
          <el-tag v-else-if="scope.row.fromType == 2" type="success"
            >微信公众号</el-tag
          >
          <el-tag v-else-if="scope.row.fromType == 3" type="success"
            >APP</el-tag
          >
          <el-tag v-else-if="scope.row.fromType == 4" type="success">H5</el-tag>
          <el-tag v-else-if="scope.row.fromType == 5" type="success"
            >支付宝小程序</el-tag
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
  </div>
</template>

<script>
import { listMixin, normal } from '@/mixins';

export default {
  mixins: [listMixin, normal],

  data() {
    return {
      searchForm: {
        userName: '',
        orderSn: '',
        startTime: '',
        endTime: '',
      },
      value: [],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  activated() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      if (this.value?.length) {
        this.searchForm.startTime = this.value[0];
        this.searchForm.endTime = this.value[1];
      } else {
        this.searchForm.startTime = null;
        this.searchForm.endTime = null;
      }
      this.$http({
        url: '/tc/userrecharge/list',
        method: 'get',
        params: {
          page: this.pageIndex,
          limit: this.pageSize,
          userName: this.searchForm.userName,
          orderSn: this.searchForm.orderSn,
          startTime: this.searchForm.startTime,
          endTime: this.searchForm.endTime,
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.records;
          this.totalPage = data.page.total;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },
  },
};
</script>
